<template>
  <label v-if="label" class="block mb-1 text-sm">{{ label }}</label>
  <input
    class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
    v-bind="$attrs"
    :value="modelValue"
    :placeholder="label"
    @input="$emit('update:modelValue', $event.target.value)"
  >
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },  
    modelValue: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>