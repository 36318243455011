import { createI18n } from "vue-i18n";
import fr from "./fr.json";
import en from "./en.json";

const messages = {
  'en': {
    ...en
  },
  'fr': {
    ...fr
  }
}

export default createI18n({
  legacy: false,
  locale: "fr",
  messages
});
