<template>
    <div v-if="!validated" class="bg-violet-200 px-4 py-6 w-full shadow rounded-lg sm:overflow-hidden">
        <h3 class="text-sm font-semibold text-white">{{ $t("live.chat.title") }}</h3>
        <div class="mt-5">
            <Label>Pseudo</Label>
            <input 
                class="mt-1 form-input block w-full border border-solid border-gray-300 text-gold-100 px-5 text-sm sm:text-md leading-10 rounded-lg focus:rounded-lg bg-white bg-opacity-10"
                @input="$emit('input', $event.target.value)" 
                ref="input"
            />
            <div v-if="error" class="text-red">{{ $t("live.error.name") }}</div>
        </div>  
        <div class="mt-5">
            <Button type="button" size="lg" color="white" :fullwidth=true @click="clickWithName">Valider</Button>
        </div>
        <!--h3 class="mt-63px text-13px font-semibold text-white">Continuer sans le chat</h3> 
        <div class="mt-16px mb-22px">
            <Button type="button" size="lg" color="white" :fullwidth=true @click="clickWithoutName">Valider</Button>
        </div--> 
    </div>
    <div v-if="validated" class="py-2 w-full px-sm-3 shadow sm:rounded-md sm:overflow-hidden absolute top-0 left-0">
        <a href="#" @click.prevent="click('none')">
            <svg class="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
            </svg>
        </a>
    </div>
    <div v-if="validated" class="py-2 w-full px-sm-3 shadow sm:rounded-md sm:overflow-hidden absolute bottom-0 right-0">
        <Input value="" radius="rounded-3xl" background="bg-transparent" placeholder="Dites quelquechose..." />
    </div>
</template>
<script>
import Button from "@/components/Button.vue";
import Label from "@/components/Label.vue";
import Input from "@/components/Input.vue";
import useEventChats from "@/composables/use-event-chats";
export default {
    props: {
        validated: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Button,
        Label,
        Input
    },
    setup() {
        const { chats } = useEventChats();
        return { chats };
    },
    data() {
        return {
            inputValue: '',
            error: false
        }
    },
    methods: {
        clickWithName() {
            this.error = false;

            if (!this.$refs.input.value) {
                this.error = true;
                this.$refs.input.focus();

                return;        
            }

            this.$emit('validate', true, this.$refs.input.value);
        },
        clickWithoutName() {
            this.$emit('validate', true);
        },
        click(data) {
            this.$emit('click', data);
        }
    }
}
</script>
