<template>
    <div :id="'video-'+participantId" :class="'video'+(isLocal ? ' local' : '')">
        <div class="relative" :class="participantId" :id="participantId">
            <video
                :id="'native-video-'+participantId"
                :ref="video"
                :muted="isLocal"
                :key="'video-'+participantId"
                :srcObject="stream"
                autoPlay
                disablePictureInstreamPicture
                playsInline
                :class="videoClass"
            />
            <template v-if="isLocal">
                <div class="absolute left-3 bottom-1 text-gray-100">
                    <button type="button" :class="'mr-3 '+(isVideoEnabled ? 'videoOff' : 'videoOn')" @click.prevent="toggleVideo()">
                        <template v-if="isVideoEnabled">
                            <video-off-icon size="1.5x"></video-off-icon>
                        </template>
                        <template v-else>
                            <video-icon size="1.5x"></video-icon>
                        </template>
                    </button>
                    <button type="button" :class="(isMuted ? 'muted' : 'unmuted')" @click.prevent="toggleAudio()">
                        <template v-if="isMuted">
                            <mic-icon size="1.5x"></mic-icon>
                        </template>
                        <template v-else>
                            <mic-off-icon size="1.5x"></mic-off-icon>
                        </template>
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { VideoOffIcon, VideoIcon, MicIcon, MicOffIcon } from "@zhuowenli/vue-feather-icons";
import { ref } from "vue";
export default {
    props: {
        participantId: {
            type: String,
            default: null,
        },
        participantName: {
            type: String,
            default: null,
        },
        isLocal: {
            type: Boolean,
            default: false,
        },
        isMuted: {
            type: Boolean,
            default: false,
        },
        isVideoEnabled: {
            type: Boolean,
            default: false,
        },
        stream: {
            type: Object,
            required: false,
            default: null,
        },
        videoClass: {
            type: String,
            default: '',
        }
    },
    components: {
        VideoOffIcon,
        VideoIcon,
        MicIcon,
        MicOffIcon
    },
    setup() {
        const video = ref(null);
        /*watch(()=> {
            if (props.stream && video) {
                video.current.srcObject = props.stream;
                video.current.onclick = () => false;
                video.current.ondblclick = toggleFullscreen;
                video.value = {
                    current: {
                        srcObject: props.stream,
                        onclick: () => false,
                        ondblclick: toggleFullscreen
                    }
                    srcObject: props.stream,
                        onclick: () => false,
                        ondblclick: toggleFullscreen                  
                }
            }
        }, []);*/

        /*const toggleFullscreen = e => {
            if (!document.fullscreenElement) {
                const fullScreenFunc = e.target.requestFullscreen ||
                    e.target.mozRequestFullScreen ||
                    e.target.webkitRequestFullscreen ||
                    e.target.msRequestFullscreen

                if (fullScreenFunc) {
                    fullScreenFunc();
                }

                e.target.controls = false;
            } else {
                document.exitFullscreen();
                e.target.controls = true;
            }
        };*/

        return { video };
    },
    methods: {
        toggleAudio() {
            this.$emit('toggle-audio');
        },
        toggleVideo() {
            this.$emit('toggle-video');
        }
    }
}
</script>