<template>
  <label v-if="label" class="block mb-1 text-sm">{{ label }}</label>
  <textarea
    class="w-full px-3 py-2 text-gray-700 focus:border-blue-500 border rounded focus:outline-none" rows="4"
    v-bind="$attrs"
    :value="modelValue"
    :placeholder="label"
    @input="$emit('update:modelValue', $event.target.value)"
  >
  </textarea>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },  
    modelValue: {
      type: String,
      default: ''
    }
  }
}
</script>