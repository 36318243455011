import repositoryFactory from "repository-factory";
import { ref } from "vue";

export default function useOrders() {
  const order = ref({});
  const error = ref(null);

  function createOrder(firstname, lastname, email, offers) {
    repositoryFactory
      .get("order")
      .createOrder(firstname, lastname, email, offers)
      .then(data => order.value = data.data.createOrder)
      .catch(e => {
        error.value = e.message
      });
  }

  return { createOrder, order, error }
}