import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';

const httpLink = new HttpLink({
    // URL to graphql server, you should use an absolute URL here
    uri: process.env.VUE_APP_API_URL
});

onError(error => {
    if (process.env.NODE_ENV !== 'production') {
        console.log(error);
        //logErrorMessages(error);
    }
});

// create the apollo client
export default new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache({
        addTypename: false
    })
    /*headers: {
        token: 'YOUR_TOKEN',
        version: 'draft',
    }*/
});