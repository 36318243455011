<template>
    <button type="button" @click.prevent="share" :style="'background:'+color" class="mb-2 bg-violet-100 hover:bg-violet-200 text-white text-13px font-medium px-97px py-14px w-full rounded-lg"><slot></slot></button>
</template>
<script>
import AvailableNetworks from '../social-share/networks.js';

let $window = typeof window !== 'undefined' ? window : null

export function mockWindow (self) {
    $window = self || window // mock window for unit testing
}

export default {
    props: {
        network: {
            type: String,
            required: true
        },

        url: {
            type: String,
            required: true
        },

        title: {
            type: String,
            required: true
        },

        description: {
            type: String,
            default: ''
        },

        quote: {
            type: String,
            default: ''
        },

        hashtags: {
            type: String,
            default: ''
        },

        twitterUser: {
            type: String,
            default: ''
        },

        popup: {
            type: Object,
            default: () => ({
                width: 626,
                height: 436
            })
        }
    },

    data () {
        return {
            popupTop: 0,
            popupLeft: 0,
            popupWindow: undefined,
            popupInterval: null,
            colors: {
                facebook: {
                    color: '#1877f2',
                },
                twitter: {
                    color: '#1da1f2',
                },
                linkedin: {
                    color: '#007bb5',
                }
            }
        }
    },

    computed: {
        networks () {
            return AvailableNetworks
        },

        color () {
            return this.colors[this.key].color;
        },

        key () {
            return this.network.toLowerCase()
        },

        rawLink () {
            const ua = navigator.userAgent.toLowerCase()

            if (this.key === 'sms' && (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1)) {
                return this.networks[this.key].replace(':?', ':&')
            }

            return this.networks[this.key]
        },

        shareLink () {
            let link = this.rawLink
            if (this.key === 'twitter') {
                if (!this.hashtags.length) link = link.replace('&hashtags=@h', '')
                if (!this.twitterUser.length) link = link.replace('@tu', '')
            }

            return link
                .replace(/@tu/g, '&via=' + encodeURIComponent(this.twitterUser))
                .replace(/@u/g, encodeURIComponent(this.url))
                .replace(/@t/g, encodeURIComponent(this.title))
                .replace(/@d/g, encodeURIComponent(this.description))
                .replace(/@q/g, encodeURIComponent(this.quote))
                .replace(/@h/g, this.encodedHashtags)
        },

        encodedHashtags () {
            if (this.key === 'facebook' && this.hashtags.length) {
                return '%23' + this.hashtags.split(',')[0]
            }

            return this.hashtags
        }
    },

    created() {
        //alert(this.url); 
    },

    methods: {
        resizePopup () {
            const width = $window.innerWidth || (document.documentElement.clientWidth || $window.screenX)
            const height = $window.innerHeight || (document.documentElement.clientHeight || $window.screenY)
            const systemZoom = width / $window.screen.availWidth

            this.popupLeft = (width - this.popup.width) / 2 / systemZoom + ($window.screenLeft !== undefined ? $window.screenLeft : $window.screenX)
            this.popupTop = (height - this.popup.height) / 2 / systemZoom + ($window.screenTop !== undefined ? $window.screenTop : $window.screenY)
        },

        /**
        * Shares URL in specified network.
        */
        share () {
            this.resizePopup()

            // If a popup window already exist, we close it and trigger a change event.
            if (this.popupWindow && this.popupInterval) {
                clearInterval(this.popupInterval)

                // Force close (for Facebook)
                this.popupWindow.close()

                this.emit('change')
            }

            this.popupWindow = $window.open(
                this.shareLink,
                'sharer-' + this.key,
                ',height=' + this.popup.height +
                ',width=' + this.popup.width +
                ',left=' + this.popupLeft +
                ',top=' + this.popupTop +
                ',screenX=' + this.popupLeft +
                ',screenY=' + this.popupTop
            )

            // If popup are prevented (AdBlocker, Mobile App context..), popup.window stays undefined and we can't display it
            if (!this.popupWindow) return

            this.popupWindow.focus()

            // Create an interval to detect popup closing event
            this.popupInterval = setInterval(() => {
                if (!this.popupWindow || this.popupWindow.closed) {
                clearInterval(this.popupInterval)

                this.popupWindow = null

                this.emit('close')
                }
            }, 500)

            this.emit('open')
        },

        touch () {
            window.open(this.shareLink, '_blank')

            this.emit('open')
        },

        emit (name) {
            this.$root.$emit('share_network_' + name, this.key, this.url)
            this.$emit(name, this.key, this.url)
        }
    }
}
</script>