import client from "api-client";
import { ALL_TICKETS_QUERY, TICKET_QUERY } from "../graphql/tickets";

export default {
  getTicket(id) {
    return client.query({query: TICKET_QUERY, variables: {
      id: id
    }});
  },

  getTickets() {
    return client.query({query: ALL_TICKETS_QUERY});
  }
};