import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ConcertInfo from "../views/ConcertInfo.vue";
import Live from "../views/Live.vue";
import Backstage from "../views/Backstage.vue";
import Terms from "../views/Terms.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      section: 'home'
    }
  },
  {
    path: "/concert/:slug",
    name: "Concert",
    props: true,
    component: ConcertInfo,
    meta: {
      section: 'concert'
    }
  },
  {
    path: "/live/:id",
    name: "Live",
    component: Live,
    meta: {
      section: 'live'
    }
  },
  {
    path: "/backstage/:id",
    name: "Backstage",
    component: Backstage,
    meta: {
      section: 'backstage'
    }
  },
  {
    path: "/terms",
    name: "General Terms",
    props: { section: 'general' },
    component: Terms,
    meta: {
      section: 'terms'
    }
  },
  {
    path: "/terms/privacy",
    name: "Privacy Policy",
    props: { section: 'privacy' },
    component: Terms,
    meta: {
      section: 'terms'
    }
  },
  {
    path: "/terms/cookie",
    name: "Cookie Policy",
    props: { section: 'cookie' },
    component: Terms,
    meta: {
      section: 'terms'
    }
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      section: '404'
    }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: '404'}
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
