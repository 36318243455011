<template>
    <teleport to="#modal-wrapper">
        <Modal2 v-if="$root.modalOpen" @close="toggleModalState" title="Share On Social Networks">
            <SocialShare v-for="network in networks"
                :network="network"
                :key="network"
                :url="url"
                :title="title"
                :description="description"
                :quote="quote"
                :hashtags="hashtags"
                :twitterUser="twitterUser">
                <span v-if="network === 'facebook'"><FacebookIcon class="float-left mr-2" />Share On Facebook</span>
                <span v-if="network === 'twitter'"><TwitterIcon class="float-left mr-2" /> Share On Twitter</span>
                <span v-if="network === 'linkedin'"><LinkedinIcon class="float-left mr-2" />Share On LinkedIn</span>
            </SocialShare>
        </Modal2>
    </teleport>
    <Button type="button" color="white" :round=round :shadow=shadow @click="showModal"><Share2Icon /></Button>
</template>
<script>
import Button from "@/components/Button.vue";
import Modal2 from "@/components/Modal2.vue";
import SocialShare from "@/components/SocialShare";
import { 
    Share2Icon, 
    FacebookIcon, 
    TwitterIcon,
    LinkedinIcon
} from '@zhuowenli/vue-feather-icons';
export default {
    components: {
        Button,
        Share2Icon,
        FacebookIcon, 
        TwitterIcon,
        LinkedinIcon,
        Modal2,
        SocialShare
    },
    props: {
        networks: {
            default: ["facebook","twitter","linkedin"]
        },

        url: {
            type: String,
            required: true
        },

        title: {
            type: String,
            required: true
        },

        description: {
            type: String,
            default: ''
        },

        quote: {
            type: String,
            default: ''
        },

        hashtags: {
            type: String,
            default: ''
        },

        twitterUser: {
            type: String,
            default: ''
        },

        round: {
            type: Boolean,
            default: false
        },
        
        shadow: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal () {
        },

        showModal () {
            this.$root.modalOpen = true;
        }
    }
}
</script>
