import client from "api-client";
import { ALL_EVENTS_QUERY, EVENT_BY_SLUG_QUERY, EVENT_QUERY } from "../graphql/events";

export default {
  getEvent(id) {
    return client.query({query: EVENT_QUERY, variables: {
      id: id
    }});
  },

  getEvents() {
    return client.query({query: ALL_EVENTS_QUERY});
  },

  getEventBySlug(slug) {
    return client.query({query: EVENT_BY_SLUG_QUERY, variables: {
      slug: slug
    }});
  }
};