<script>
import { getComponent } from "./ComponentTypes";
import { h } from "vue";
export default {
    props: {
        ticket: {
            type: Object,
            default: null,
        }
    },
    functional: true,
    render() {
        if (!this.ticket.id) {
            return null;
        }
        return h(getComponent("TICKET"), {
            ticket: this.ticket
        });
    }
}
</script>