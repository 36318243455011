import repositoryFactory from "repository-factory";
import { ref } from "vue";

export default function useTickets() {
    const ticket        = ref({});
    const mainTicket    = ref({});
    const tickets       = ref({});
    const error         = ref(null);

    function getTickets() {
        repositoryFactory
            .get("ticket")
            .getTickets()
            .then(data => (tickets.value = data.data.getTickets))
            .catch(e => {
                error.value = e.message;
            });
    }

    function getTicket(id) {
        repositoryFactory
            .get("ticket")
            .getTicket(id)
            .then(data => {
                ticket.value = data.data.getTicket;
                if (!data.data.getTicket) {
                    error.value = 'No data found';
                }
            }).catch(e => {
                error.value = e.message;
            });
    }

    function getVideoId(videoId) {
        if (videoId) {
            let videoIdParts = videoId.split('/');
            return videoIdParts[0];
        }
    }

    function getChatId(videoId) {
        if (videoId) {    
            let videoIdParts = videoId.split('/');
            return videoIdParts[0]+'/chat/'+videoIdParts[1];
        }    
    }  

    function getMainScreen(tickets) {
        tickets.each(function(t) {
            if (t.main_screen) {
                mainTicket.value = t;
                //break;
            }
        });
    } 

    return { getTickets, getTicket, getVideoId, getChatId, getMainScreen, mainTicket, ticket, tickets, error };
}
