<template>
    <div v-if="activeMenu == 'video_show_horizontal'" class="absolute top-80 right-0" style="top: 8rem; right: 2rem;">  
        <Button type="button" :size="sm" :color="base" :fullwidth="false" float="right1" @click="showVertical">{{ $t("live.button.vertical") }}</Button>
    </div>
    <div class="">
        <div v-if="activeMenu == 'video_show_horizontal'" class="md:grid md:grid-cols-12 md:gap-6 max-w-screen-xl mx-auto">
            <!--div class="md:col-span-11"-->  
                <div v-for="participant in participantsRef" :key="participant.participantId" class="md:col-span-3">  
                    <Video 
                        @toggleAudio="toggleAudio" 
                        @toggleVideo="toggleVideo" 
                        @shareScreen="shareScreen" 
                        :participantId="participant.participantId"
                        :participantName="participant.participantName"
                        :isMuted="isMuted"
                        :isVideoEnabled="isVideoEnabled"
                        :stream="participant.stream"
                        :isLocal="participant.isLocal"
                        videoClass="w-full max-h-36 object-cover object-center md:border-2 md:border-gray-700"
                    />  
                </div>
            <!--/div--> 
        </div>
        <div class="md:grid md:grid-cols-12">
            <div :class="videoClassComputed">    
                <div class="embed-container h-72 sm:h-96" :class="activeMenu.replaceAll('_', '-')">
                    <iframe :src="'//vimeo.com/event/'+getVideoId(ticket.video_id)+'/embed'" width="100%" height="100%" frameborder="0" class="h-56"></iframe>
                </div>
            </div>
            <div v-if="activeMenu == 'video_show_vertical'" class="relative p-5 md:col-span-4 lg:col-span-3 md:self-center">
                <ChatBox v-if="!isValidated" @validate="validate"></ChatBox>
                <template v-if="isValidated"> 
                    <div class="py-2 text-right hidden md:block">
                        <Button type="button" :size="sm" :color="base" float="right" class="mb-3" @click="showHorizontal">{{ $t("live.button.horizontal") }}</Button>
                        <a href="#" class="hidden md:block">
                            <svg class="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                            </svg>
                        </a>
                    </div>
                    <div class="flex flex-wrap md:flex-col md:space-y-2 clear-both">
                        <div v-for="participant in participantsRef" :key="participant.participantId" class="w-1/2 md:w-full self-center">
                            <Video
                                @toggle-audio="toggleAudio"
                                @toggle-video="toggleVideo"
                                @shareScreen="shareScreen"
                                :participantId="participant.participantId"
                                :participantName="participant.participantName"
                                :isMuted="isMuted"
                                :isVideoEnabled="isVideoEnabled"
                                :isLocal="participant.isLocal"
                                :stream="participant.stream"
                                videoClass="w-full object-cover object-center max-h-40 sm:max-h-44 md:border-2 md:border-gray-700"
                            />
                        </div>
                    </div>
                </template> 
            </div>
        </div>
    </div>           
</template>
<script>
import Button from "@/components/Button.vue";
import Video from "@/components/Video.vue";
import ChatBox from "@/components/ChatBox.vue";
import useTickets from "@/composables/use-tickets";
import useWatchTogether from "@/composables/use-watch-together";
export default {
    props: {
        ticket: {
            type: Object,
            default: null,
        },
    },
    components: {
        Video,
        ChatBox,
        Button
    },
    computed: {
        videoClassComputed() {
            return this.videoClass;
        },
        isValidated() {
            return this.validated;
        }
    },
    setup() {
        const { 
            toggleAudio, 
            toggleVideo, 
            shareScreen,
            startConnecting,
            participantsRef,
            isMuted,
            isVideoEnabled
        } = useWatchTogether();

        const { getVideoId } = useTickets();        

        return {  
            toggleAudio, 
            toggleVideo, 
            shareScreen,
            startConnecting,
            participantsRef,
            isMuted,
            isVideoEnabled,
            getVideoId
        };
    },
    data() {
        return {
            activeMenu: 'video_show_vertical',
            videoClass: 'md:col-span-8 lg:col-span-9',
            validated: false,
        }
    },
    methods: {
        toggle(menu) {
            this.activeMenu = menu;
            switch(menu) {
                case 'video_show_vertical':
                    this.videoClass = 'md:col-span-8 lg:col-span-9';
                break;
                case 'video_show_horizontal':
                    this.videoClass = 'md:col-span-12';
                break;
                default:
                    this.activeMenu = 'none';
                    this.videoClass = 'md:col-span-12 text-center';
            }
        },
        showVertical() {
            this.toggle('video_show_vertical');
        },
        showHorizontal() {
            this.toggle('video_show_horizontal');
        },
        validate(value, name) {
            this.validated = value;
            this.startConnecting(
                this.ticket.sceenic_token.fullToken, 
                this.ticket.sceenic_token.url, 
                this.ticket.sceenic_token.room, 
                name
            );
        }
    }
};
</script>
<style scoped>
.embed-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    background: black;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
iframe form {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
@media screen and (min-width: 768px) {
    .embed-container.video-show-vertical {
        height: calc(100vh - 69px);
    }
    .embed-container.video-show-horizontal {
        height: calc(100vh - 260px);
    }
}
</style>