import client from "api-client";
import { CREATE_ORDER_MUTATION } from "../graphql/orders";

export default {
  createOrder(firstname, lastname, email, offers) {
    return client.mutate({
      mutation: CREATE_ORDER_MUTATION,
      variables: {
        firstname: firstname,
        lastname: lastname,
        email: email,
        offers: offers
      }
    });
  }
}