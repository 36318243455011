<template>

  <section class="intro bg-center bg-cover">
    <NavBar class="sm:hidden" />
    <NavBar class="hidden sm:block" :transparent="true" />
    <div class="container mx-auto sm:py-10 md:py-20">
      <h1 class="text-white text-xl px-5 py-20 sm:text-3xl ">
        {{ $t("home.intro.watch") }}
        <span class="text-gold-100 block italic">{{ $t("home.intro.live") }}</span>
        {{ $t("home.intro.wherever") }}.
      </h1>
    </div>
  </section>

  <section v-if="promoted" class="featured bg-center bg-cover text-white">
    <div class="container mx-auto px-4 py-10 sm:py-20">
      <div class="flex flex-col md:flex-row">
        <div class="md:w-2/3">
          <img :src="promoted.image" class="rounded-lg" />
        </div>
        <div class="mt-10 md:px-5 md:self-center md:mt-0 md:w-1/3">
          <h2 class="text-xl font-medium">{{ promoted.title }}</h2>
          <div class="mb-5 text-sm">
            <span class="text-gold-100 font-bold text-lg">
              {{ moment(promoted.date).format('D') }}
            </span>
            {{ moment(promoted.date).format('MMMM [-] HH:mm') }}
          </div>
          <div class="flex">
            <RouterLink :to="{ name: 'Concert', params: { slug: promoted.slug }}" class="bg-violet-100 hover:bg-violet-200 text-white text-xs font-medium px-4 py-3 rounded-lg mr-2">
              {{ $t("home.featured.showTickets") }}
            </RouterLink>
            <ShareButton
                url="$root.getFullRouteUrl({name: 'Concert', params: { slug: promoted.slug }})"
                title=""
                description=""
                quote=""
                hashtags=""
                :key="promoted.slug"
            >
            </ShareButton>  
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="upcoming bg-gray-100">
    <div class="container mx-auto px-4">
      <h2 class="text-lg font-semibold pt-10 sm:pt-20">{{ $t("home.upcoming.title") }}</h2>
      <div class="bg-violet-100 h-1 w-12 my-4"></div>

      <div class="grid grid-cols-1 sm:grid-cols-3 gap-8 pt-10 pb-16">
        <div v-for="event in upcomingEvents" :key="event.id" class="rounded-xl overflow-hidden shadow-lg">
          <RouterLink :to="{ name: 'Concert', params: { slug: event.slug }}">
            <img class="w-full" :src="event.image" >
          </RouterLink>
          <div class="p-4">
            <div class="flex justify-between">
              <div class="mb-2 text-gray-900 md:text-lg">
                <RouterLink :to="{ name: 'Concert', params: { slug: event.slug }}">
                  {{ event.title }}
                </RouterLink>
              </div>
              <div class="self-start">
                <span v-if="event.new" class="inline-block bg-gold-200 rounded-full px-2 py-1 text-sm font-semibold text-white">
                  {{ $t("home.upcoming.new") }}
                </span>
              </div>
            </div>
            <div class="flex justify-between items-center">
              <p class="text-gray-700 uppercase text-xs">
                <span class="font-bold text-violet-200 text-base">
                  {{ moment(event.date).format('D') }}
                </span>
                {{ moment(event.date).format('MMMM') }}
              </p>
              <ShareButton
                  :url="$root.getFullRouteUrl({name: 'Concert', params: { slug: event.slug }})"
                  title=""
                  description=""
                  quote=""
                  hashtags=""
                  :key="event.slug"
              >
              </ShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="with-friends bg-gradient-to-r from-violet-200 to-black">
    <div class="container mx-auto px-4">
      <div class="py-20 text-white">
        <div class="flex flex-col md:flex-row space-y-8 md:space-x-4 md:space-y-0">
          <div class="self-center flex-grow">
            <h2 class="font-bold text-lg mb-5">
              {{ $t("home.withFriends.title") }}
              <span class="text-gold-100">{{ $t("home.withFriends.titleGold") }}</span>
            </h2>
            <p class="text-xs leading-6 md:text-sm md:leading-6">
              {{ $t("home.withFriends.text") }}
            </p>
          </div>
          <div class="self-center">
            <img src="http://lorempixel.com/600/300/abstract" class="rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="artists bg-center bg-cover">
    <div class="container mx-auto px-4">
      <div class="py-20 text-white text-center">
        <h2 class="text-lg font-semibold my-2">{{ $t("home.artists.title") }}</h2>
        <p class="text-sm mb-10">{{ $t("home.artists.text") }}</p>
        <Button type="button" @click="toggleModal">
          {{ $t("home.artists.contact") }}
        </Button>
      </div>
    </div>
  </section>

  <Modal v-if="showModal" :showHeader="false" width="lg" v-on:close="toggleModal">
    <Contact @closeModal="toggleModal" />
  </Modal>

  <Footer />
</template>

<script>
// @ is an alias to /src
import NavBar  from "@/components/NavBar.vue";
import Footer  from "@/components/Footer.vue";
import Button  from "@/components/Button.vue";
import Contact from "@/components/Contact.vue";
import Modal   from "@/components/Modal.vue";
import ShareButton from "@/components/ShareButton.vue";
import useEvents from "@/composables/use-events";
import { ref, onMounted, inject, computed } from "vue";

export default {
  name: "Home",
  components: {
    NavBar,
    Footer,
    Contact,
    Modal,
    Button,
    ShareButton
  },
  setup() {
    const moment = inject('moment');
    const showModal = ref(false);
    const { getEvents, events } = useEvents();
    const promoted = computed(() => {
      for (let i = 0; i < events.value.length; i++) {
        if(events.value[i].promoted) {
          return events.value[i];
        }
      }
      return null;
    });
    const upcomingEvents = computed(() => {
      let upcoming = [];
      for (let i = 0; i < events.value.length; i++) {
        if(!events.value[i].promoted) {
          upcoming.push(events.value[i]);
        }
      }
      return upcoming;
    })
    onMounted(() => {
      getEvents();
    });
    function toggleModal() {
      showModal.value = !showModal.value;
    }
    return { moment, showModal, toggleModal, promoted, upcomingEvents };
  }
};
</script>

<style scoped>
.intro {
  background-image: url('../assets/img/section-bg/wt-live.jpg');
}
.featured {
  background-image: url('../assets/img/section-bg/bw-01.jpg');
}
.intro {
  background-image: url('../assets/img/section-bg/wt-live.jpg');
}
.artists {
  background-image: url('../assets/img/section-bg/bw-03.jpg');
}
</style>
