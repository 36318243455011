<template>
  <footer class="bg-gray-800 text-white text-center">
    <div class="container mx-auto px-9">
      <h2 class="pt-8 pb-4">{{ $t("footer.social.title") }}</h2>
      <div class="flex items-center justify-center space-x-4 text-xs p-4">
        <div><a href="">{{ $t("footer.social.facebook") }}</a></div>
        <div><a href="">{{ $t("footer.social.instagram") }}</a></div>
        <div><a href="">{{ $t("footer.social.twitter") }}</a></div>
        <div><a href="">{{ $t("footer.social.linkedin") }}</a></div>
      </div>
      <hr class="my-4">
      <div class="flex sm:flex-row flex-col justify-between py-2">
        <p class="text-xs p-2 sm:order-4">
          <router-link :to="{ name: 'General Terms' }">
            {{ $t("footer.links.termsAndConditions") }}
          </router-link>
        </p>
        <p class="text-xs p-2 sm:order-5">
          <router-link :to="{ name: 'Privacy Policy' }">
            {{ $t("footer.links.dataPolicy") }}
          </router-link>
        </p>
        <p class="flex-grow sm:order-3"></p>
        <img src="../assets/img/aplat-simple-blanc.svg" class="w-10 mt-4 mx-auto sm:w-5 sm:mt-0 sm:mx-0 sm:order-1" />
        <p class="text-sm p-2 sm:order-2 sm:text-xs">&copy; {{ copyYear }} Bubblive</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    copyYear() {
      let d = new Date();
      return d.getFullYear();
    }
  }
};
</script>

<style scoped>
  a:hover,
  a:active {
    color: #BC8F46;
  }
</style>