<template>
  <div class="flex justify-around place-content-center m-10 text-center sm:w-">
    <div>
      <div class="rounded-full h-10 w-10 bg-gold-300 text-white pt-2 mx-auto">
        1
      </div>
      <h3 class="font-semibold text-sm my-4">{{ $t('checkout.steps.identity') }}</h3>
    </div>
    <div :class="{ 'opacity-50' : step < 2 }">
      <div class="rounded-full h-10 w-10 bg-gold-300 text-white pt-2 mx-auto">
        2
      </div>
      <h3 class="font-semibold text-sm my-4">{{ $t('checkout.steps.payment') }}</h3>
    </div>
    <div :class="{ 'opacity-50' : step < 3 }">
      <div class="rounded-full h-10 w-10 bg-gold-300 text-white pt-2 mx-auto">
        3
      </div>
      <h3 class="font-semibold text-sm my-4">{{ $t('checkout.steps.confirmation') }}</h3>
    </div>
  </div>

  <div v-if="step == 1">
    <h2 class="text-center font-semibold my-12">{{ $t("checkout.titles.identity") }}</h2>

    <form @submit.prevent="initPaymentProcess()">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
        <div>
          <BaseInput
            v-model="user.firstname"
            type="text"
            :label="$t('checkout.form.label.firstname')"
            :class="{ 'border-red-500': v.user.firstname.$error}"
            @blur="v.user.firstname.$touch()"
          />

          <div v-if="v.user.firstname.$error" class="text-xs text-red-600 mt-1">
            <p v-for="error in v.user.firstname.$errors" :key="error.$property">
              {{ error.$message }}
            </p>
          </div>
        </div>

        <div>
          <BaseInput
            v-model="user.lastname"
            type="text"
            :label="$t('checkout.form.label.lastname')"
            :class="{ 'border-red-500': v.user.lastname.$error}"
            @blur="v.user.lastname.$touch()"
          />

          <div v-if="v.user.lastname.$error" class="text-xs text-red-600 mt-1">
            <p v-for="error in v.user.lastname.$errors" :key="error.$property">
              {{ error.$message }}
            </p>
          </div>

        </div>

        <div class="md:col-span-2">
          <BaseInput
            v-model="user.email"
            type="email"
            :label="$t('checkout.form.label.email')"
            :class="{ 'border-red-500': v.user.email.$error}"
            @blur="v.user.email.$touch()"
          />

          <div v-if="v.user.email.$error" class="text-xs text-red-600 mt-1">
            <p v-for="error in v.user.email.$errors" :key="error.$property">
              {{ error.$message }}
            </p>
          </div>
        </div>

        <div class="md:col-span-2 text-center">
          <Button type="submit" size="lg" :disabled="v.$anyerror">
            {{ $t("checkout.form.submit") }}
          </Button>
        </div>
      </div>
    </form>
  </div>

  <div v-else>
    <h2 v-if="step == 2" class="text-center font-semibold my-12">{{ $t("checkout.titles.payment") }}</h2>
    <h2 v-else class="text-center font-semibold my-12">{{ $t("checkout.titles.confirmation") }}</h2>

    <AlertMessage type="danger" v-if="error" class="mb-10">
      <template #message>{{ error }}</template>
    </AlertMessage>

    <form v-if="step == 2 && !error" name="payment-data" :action="order.action" method="POST" target="payment-frame">
      <input v-for="(element, index) in order.form" :key="index" :name="index.toUpperCase()" :value="element" type="hidden" />
      <div v-if="iframeLoadCount < 2" class="text-center">
        <Button type="submit" size="lg">Proceed to payment</Button>
      </div>
    </form>

    <iframe name="payment-frame" class="w-96 h-96 m-auto md:mx-40" @load="iframeOnLoad" src=""></iframe>

    <div v-if="step == 3" class="text-center">
      <Button type="button" @click="$emit('close-modal')">Close</Button>
    </div>
  </div>

</template>

<script>
import { ref, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import AlertMessage from "@/components/AlertMessage.vue";
import Button from "@/components/Button.vue";
import useOrders from "@/composables/use-orders";

export default {
  name: "Checkout",
  props: {
    cart : {
      type: Object,
      required: true
    }
  },
  components: {
    AlertMessage,
    Button
  },
  setup(props) {
    const step = ref(1)
    const iframeLoadCount = ref(0)
    const user = reactive({
      firstname: "",
      lastname: "",
      email: ""
    })
    const validationArgs = {
      user: {
        firstname: {
          required
        },
        lastname: {
          required
        },
        email: {
          required, email
        },
        required
      }
    };
    const v = useVuelidate(validationArgs, { user });
    const { createOrder, order, error } = useOrders();

    function initPaymentProcess() {
      v.value.$touch()
      if (!v.value.$invalid) {
        // API call to create order
        createOrder(user.firstname, user.lastname, user.email, props.cart.items);
        // Build payment form, and submit to target iframe
        step.value = 2;
      }
    }

    function iframeOnLoad() {
      // Unable to detect iframe source here, so we do this based on load count
      // massive limitation is if server side valiation fails in iframe,
      // then that load should not count...
      if(iframeLoadCount.value > 1) {
        step.value = 3
      }
      iframeLoadCount.value ++
    }

    return {step, user, order, error, v, initPaymentProcess, iframeLoadCount, iframeOnLoad}
  }
};
</script>