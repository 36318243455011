<template>
    <div class="flex flex-col min-h-screen" :class="$route.meta.section">
        <router-view />
    </div>
    <CookieLaw
        :message="$t('cookies.message')"
        :buttonLink="{ name: 'Cookie Policy' }"
        :buttonLinkText="$t('cookies.buttonLinkText')"
        :buttonText="$t('cookies.buttonText')"
    />
</template>
<script>
import CookieLaw from "@/components/CookieLaw.vue";
import { ref } from "vue";
export default {
    name: "App",
    components: {
        CookieLaw
    },
    setup () {
        const modalOpen = ref(false);
        const toggleModalState = () => {
            modalOpen.value = !modalOpen.value;
        };
        return {
            modalOpen,
            toggleModalState
        }
    }, 
    methods: {
        getFullRouteUrl (params) {
            return window.location.origin + this.$router.resolve(params).href;
        }
    }    
};
</script>
