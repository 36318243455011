import EventRepository   from './EventRepository';
import MessageRepository from './MessageRepository';
import OrderRepository   from './OrderRepository';
import TicketRepository  from './TicketRepository';

const repositories = {
  'event'   : EventRepository,
  'message' : MessageRepository,
  'order'   : OrderRepository,
  'ticket'  : TicketRepository,
}
export default {
  get(name) {
    return repositories[name]
  }
};