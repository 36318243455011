<template>
  <nav :class="!transparent ? 'bg-white' : ''">
    <div class="p-4" :class="{ 'container mx-auto': container }">
      <router-link :to="{ name: 'Home' }" class="p-2">
        <img
          v-if="transparent"
          class="w-20 inline"
          src="../assets/img/aplat-complet-blanc.svg"
        />
        <img
          v-else
          class="w-20 inline"
          src="../assets/img/aplat-complet-noir.svg"
        />
      </router-link>

      <div class="float-right p-2" :class="transparent ? 'text-white' : ''">
        <slot>
          <SearchIcon class="inline mx-5 align-middle" size="20" />
          <SelectLocale />
        </slot>
      </div>
    </div>
  </nav>
</template>

<script>
import { SearchIcon } from '@zhuowenli/vue-feather-icons'
import SelectLocale from '@/components/SelectLocale.vue'

export default {
  name: "NavBar",
  components: {
    SearchIcon,
    SelectLocale
  },
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
    container: {
      type: Boolean,
      default: true
    }
  }
};
</script>