import repositoryFactory from "repository-factory";
import { ref } from "vue";

export default function useMessages() {
  const messageSent = ref(false);
  const error = ref(null);

  function sendMessage(firstName, lastName, email, message) {
    repositoryFactory
      .get("message")
      .sendMessage(firstName, lastName, email, message)
      .then(data => messageSent.value = data.data.sendMessage)
      .catch(e => {
        error.value = e.message
      });
  }

  return { sendMessage, messageSent, error }
}