<template>
    <div class="bg-black-3 bg-center bg-cover min-h-screen">
        <NavBar :container="false">
            <div class="mr-2">
                <ul class="flex">
                    <li class="ml-8">
                        <a href="#" @click.prevent="toggle('connect')">
                            <share-2-icon size="1.8x"></share-2-icon>
                        </a>
                    </li>
                    <li class="ml-8" v-if="ticket.type != 'Standard'">
                        <a href="#" @click.prevent="toggle('video_show_vertical')">
                            <video-icon size="1.8x"></video-icon>
                        </a>
                    </li>
                </ul>
            </div>
        </NavBar>
        <SuspenseWithError>
            <template #default> 
                <AlertMessage type="danger" v-if="ticket.type === 'VIP' || error != null">
                    <template #message>
                        <slot name="error"><strong>{{ $t("live.error.message1") }}</strong>. {{ $t("live.error.message2") }}</slot>
                    </template>
                </AlertMessage> 
                <ticket-provider v-else :ticket="ticket" />
            </template>
            <template #fallback>
                <span>{{ $t("live.loading") }}</span>
            </template>  
            <template #error>
                <slot name="error">{{ error }}</slot>
            </template>
        </SuspenseWithError>
    </div>            
</template>
<script>
import TicketProvider from "@/components/tickets/TicketProvider.vue";
import NavBar from "@/components/NavBar.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import { VideoIcon, Share2Icon } from "@zhuowenli/vue-feather-icons";
import SuspenseWithError from "@/components/SuspenseWithError.vue";
import useTickets from "@/composables/use-tickets";
import router from "@/router";
import { onMounted } from "vue";
export default {
    components: {
        Share2Icon,
        VideoIcon,
        SuspenseWithError,
        NavBar,
        TicketProvider,
        AlertMessage
    },
    setup() {
        const { getTicket, ticket, error } = useTickets();
        const { currentRoute } = router;
        onMounted(async () => await getTicket(currentRoute.value.params.id));        
        return { ticket, error };
    }
};
</script>
<style scoped>
.bg-black-3 {
    background-image: url('../assets/img/section-bg/bw-03.jpg');
}
</style>
