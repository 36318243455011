<template>
    <div v-if="activeMenu == 'video_show_horizontal'" class="absolute top-24 right-4">
        <Button type="button" :size="sm" :color="base" :fullwidth="false" float="right1" @click="showVertical">{{ $t("live.button.vertical") }}</Button>
    </div>
    <div class="container mx-auto px-5 py-16 sm:py-32 lg:py-24 xl:py-16">
        <div v-if="activeMenu == 'video_show_vertical' && isValidated" class="hidden md:block">
            <Button type="button" :size="sm" :color="base" float="right" class="mb-3" @click="showHorizontal">{{ $t("live.button.horizontal") }}</Button>
        </div>
        <div v-if="activeMenu == 'video_show_horizontal'" class="md:grid md:grid-cols-12 md:gap-6 mb-3">
            <div v-for="participant in viewerParticipants" :key="participant.participantId" class="md:col-span-4 place-self-center">
                <Video
                    @toggleAudio="toggleAudio"
                    @toggleVideo="toggleVideo"
                    @shareScreen="shareScreen"
                    :participantId="participant.participantId"
                    :participantName="participant.participantName"
                    :isMuted="isMuted"
                    :isVideoEnabled="isVideoEnabled"
                    :stream="participant.stream"
                    :isLocal="participant.isLocal"
                    videoClass="w-full max-h-44 object-cover object-center md:border-2 md:border-gray-700"
                />
            </div>
        </div>
        <div class="md:grid md:grid-cols-12 md:gap-6 md:align clear-both">
            <div :class="videoClassComputed">    
                <div v-for="participant in mainParticipant" :key="'main-' + participant.participantId" class="main-video embed-container">
                    <Video 
                        @toggleAudio="toggleAudio"
                        @toggleVideo="toggleVideo"
                        @shareScreen="shareScreen"
                        :participantId="participant.participantId"
                        :isMuted="isMuted"
                        :isVideoEnabled="isVideoEnabled"
                        :stream="participant.stream"
                        :isLocal="participant.isLocal"
                        videoClass="w-full max-h-screen-3/4 object-cover object-center md:border-2 md:border-gray-700"
                    />  
                </div>
            </div>
            <div v-if="activeMenu == 'video_show_vertical'" class="md:col-span-3 relative">  
                <ChatBox v-if="!isValidated" @validate="validate"></ChatBox>
                <template v-if="isValidated"> 
                    <div v-for="participant in viewerParticipants" :key="participant.participantId" class="mb-2">  
                        <Video 
                            @toggle-audio="toggleAudio" 
                            @toggle-video="toggleVideo" 
                            @shareScreen="shareScreen" 
                            :participantId="participant.participantId"
                            :participantName="participant.participantName"
                            :isMuted="isMuted"
                            :isVideoEnabled="isVideoEnabled"
                            :isLocal="participant.isLocal"
                            :stream="participant.stream"
                            videoClass="w-full max-h-64 md:max-h-48 object-cover object-center md:border-2 md:border-gray-700"
                        />  
                    </div>
                </template> 
            </div>
        </div>
    </div>           
</template>
<script>
import Button from "@/components/Button.vue";
import Video from "@/components/Video.vue";
import ChatBox from "@/components/ChatBox.vue";
import useTickets from "@/composables/use-tickets";
import useWatchTogether from "@/composables/use-watch-together";
export default {
    props: {
        ticket: {
            type: Object,
            default: null,
        },
    },
    components: {
        Video,
        ChatBox,
        Button
    },
    computed: {
        videoClassComputed() {
            return this.videoClass;
        },
        isValidated() {
            return this.validated;
        },
        viewerParticipants() {
            return this.participantsRef.filter((p) => p.participantName  !== "admin");
        },
        mainParticipant() {
            return this.participantsRef.filter((p) => p.participantName  === "admin");
        }
    },
    setup() {
        const { 
            toggleAudio, 
            toggleVideo, 
            shareScreen,
            startConnecting,
            participantsRef,
            isMuted,
            isVideoEnabled,
            isMainViewerMode
        } = useWatchTogether();

        const { getVideoId } = useTickets(); 

        return {  
            toggleAudio, 
            toggleVideo, 
            shareScreen,
            startConnecting,
            participantsRef,
            isMuted,
            isVideoEnabled,
            isMainViewerMode,
            getVideoId
        };
    },
    created() {
        if (this.ticket.main_screen) {
            this.validated = true;
            this.startConnecting(
                this.ticket.sceenic_token.fullToken, 
                this.ticket.sceenic_token.url, 
                this.ticket.sceenic_token.room, 
                'admin'                
            );
        }        
    },
    data() {
        return {
            activeMenu: 'video_show_vertical',
            videoClass: 'md:col-span-9',
            validated: false,
        }
    },
    methods: {
        toggle(menu) {
            this.activeMenu = menu;
            switch(menu) {
                case 'video_show_vertical':
                    this.videoClass = 'md:col-span-9';
                break;
                case 'video_show_horizontal':
                    this.videoClass = 'md:col-span-12';
                break;
                default:
                    this.activeMenu = 'none';
                    this.videoClass = 'md:col-span-12 text-center';
            }
        },
        showVertical() {
            this.toggle('video_show_vertical');
        },
        showHorizontal() {
            this.toggle('video_show_horizontal');
        },
        validate(value, name) {
            if (name === 'admin') {
                alert('Unable to use that name.');
            } else {
                this.validated = value;
                this.startConnecting(
                    this.ticket.sceenic_token.fullToken, 
                    this.ticket.sceenic_token.url, 
                    this.ticket.sceenic_token.room, 
                    name                
                );
            }            
        }
    }
};
</script>
<style scoped>
.embed-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    background: black;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
iframe form {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
</style>