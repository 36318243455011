<template>
    <button v-if="type == 'submit'" :type="type" :class="classComputed" :disabled="disabled" v-bind="$attrs">
        <slot></slot>
    </button>
    <button v-if="type == 'button'" :type="type" @click="click" :class="classComputed" :disabled="disabled" v-bind="$attrs">
        <slot></slot>
    </button>
</template>
<script>
export default {
    emits: ["click"],
    props: {
        size: {
            type: String,
            default: 'sm',
        },
        type: {
            type: String,
            default: 'submit',
        },
        color: {
            type: String,
            default: 'base',
        },
        fullwidth: {
            type: Boolean,
            default: false,
        },
        float: {
            type: String,
            default: '',
        },
        round: {
            type: Boolean,
            default: false
        },
        shadow: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            class: ''
        }
    },
    computed: {
        classComputed() {
            let computedClass
            computedClass =  (this.fullwidth? ' w-full': '')
            computedClass += (this.float? ' float-' + this.float: '')
            computedClass += (this.round? ' rounded-full h-12 y-12' : ' rounded-lg')
            computedClass += (this.shadow? ' shadow-lg' : '')
            computedClass += (this.disabled? ' disabled:bg-gray-700' : '')
            return this.class + computedClass
        }
    },
    created() {    
        switch(true) {
            case this.size == 'sm' && this.color == 'base':
                this.class = 'bg-violet-100 hover:bg-violet-200 text-white text-xs font-medium px-4 py-3';
            break;
            case this.size == 'sm' && this.color == 'white':
                this.class = 'bg-gray-100 hover:bg-white-100 text-black text-xs font-medium px-4 py-3';
            break;
            case this.size == 'lg' && this.color == 'base':
                this.class = 'bg-violet-100 hover:bg-violet-200 text-white text-sm font-medium px-auto py-4 w-64 max-w-full';
            break;
            case this.size == 'lg' && this.color == 'white':
                this.class = 'bg-gray-100 hover:bg-gray-100 text-black text-sm font-medium px-auto py-4 w-64 max-w-full';
            break;
            default:
                this.class = 'bg-violet-200 hover:bg-violet-200 text-white text-xs font-medium px-4 py-3';
        }
    },
    methods: {
        click() {
            this.$emit('click')
        }
    }
}
</script>
