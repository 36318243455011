<template>
    <input 
        :class="classComputed" 
        :placeholder="placeholder" 
        @input="$emit('input', $event.target.value)" 
        ref="input"
    />
</template>
<script>
export default {
    props: {
        background: {
            type: String,
            default: 'bg-violet',
        },
        radius: {
            type: String,
            default: 'rounded-lg',
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            class: ''
        }
    },
    computed: {
        classComputed() {
            return this.class;
        }
    },
    created() {    
        this.class = 'mt-1 form-input block w-full border border-solid border-gray-550 text-orange-450 px-11px text-13px sm:text-md sm:leading-10 ' + this.background +' '+ this.radius;
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        }
    }
}
</script>
