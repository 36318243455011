<template>
    <header>
        <div class="h-96 concert-header bg-cover bg-center" :style="{'background-image': 'url('+event.image+')'}">
            <NavBar v-bind:transparent="true" />
        </div>
        <div class="container mx-auto px-4 lg:max-w-4xl">
            <div class="flex space-x-4">
                <div class="bg-white rounded-xl shadow-lg uppercase text-sm text-center px-5 py-2 z-10">
                    <h2 class="font-bold text-lg text-violet-200">
                        {{ moment(event.date).format('D') }}
                    </h2>
                    <p>{{ moment(event.date).format('MMMM') }}</p>
                    <p>{{ moment(event.date).format('YYYY') }}</p>
                    <p>-</p>
                    <p>{{ moment(event.date).format('HH:mm') }}</p>
                </div>
                <div class="flex-grow z-10">
                    <h1 class="text-xl font-semibold text-white uppercase pt-8">
                        {{ event.title }}
                    </h1>
                </div>
                <div class="self-end z-10 pb-5"><ShareButton :round="true" :shadow="true" /></div>
            </div>
        </div>
    </header>
    <section class="info text-gray-900 py-12">
        <div class="container mx-auto px-4 lg:max-w-4xl">
            <span v-html="event.description"></span>
            <iframe v-if="event.video" :src="'//player.vimeo.com/video/'+event.video" class="rounded-lg mt-10 w-full h-64 mx-auto" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
    </section>
    <section class="tickets bg-top bg-cover">
        <div class="container mx-auto px-4 lg:max-w-4xl">
            <h2 class="text-lg font-semibold text-white pt-16">
                {{ $t("concertInfo.tickets.title") }}
            </h2>

            <TicketCard v-for="offer in event.offers" :key="offer.id" :offer="offer" @add-to-cart="updateCart" @remove-from-cart="updateCart" />
        </div>
        <div class="container mx-auto sm:px-4 sm:pb-10 lg:max-w-4xl">
            <div class="bg-gray-200 sm:rounded-2xl px-6 py-6 md:py-4 flex justify-between items-center">
                <div>
                    {{ $t("concertInfo.tickets.summary", cart.items.length) }}
                    <span v-if="cart.items.length" class="text-gold-300">{{ cart.total }} €</span>
                </div>
                <Button type="button" :disabled="cart.items.length === 0 ? true : false" @click="toggleModal">
                    {{ $t("concertInfo.tickets.reserve") }}
                </Button>
            </div>
        </div>
    </section>

    <Modal v-if="showModal" title="Buy tickets" :showHeader="false" width="lg" v-on:close="toggleModal">
        <Checkout :cart="cart" @closeModal="toggleModal" />
    </Modal>

    <Footer />
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Button from "@/components/Button.vue";
import Checkout from "@/components/Checkout.vue";
import ShareButton from "@/components/ShareButton.vue";
import TicketCard from "@/components/TicketCard.vue";
import useEvents from "@/composables/use-events";
import Modal from "@/components/Modal.vue";
import { onMounted, inject, ref, reactive } from "vue";
export default {
    props: ['slug'],
    components: {
        NavBar,
        Button,
        Checkout,
        Footer,
        TicketCard,
        ShareButton,
        Modal
    },
    setup(props) {
        const moment = inject('moment');
        const { getEventBySlug, event } = useEvents();
        const showModal = ref(false);
        const cart = reactive({
            items: [],
            total: 0,
        });

        function updateCart(id, remove = false) {
            if( remove ) {
                cart.items.splice(cart.items.indexOf(id), 1);
                cart.total -= event.value.offers.find(x => x.id === id).price;
            }else{
                cart.items.push(id);
                cart.total += event.value.offers.find(x => x.id === id).price;
            }
        }

        function toggleModal() {
            showModal.value = !showModal.value;
        }
        onMounted(() => getEventBySlug(props.slug));
        return { event, moment, cart, updateCart, showModal, toggleModal };
    }
};
</script>

<style scoped>

.concert-header {
    -webkit-mask: url('../assets/img/concert-mask.svg');
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: bottom;
}

header .container {
    margin-top: -100px;
}

.tickets {
    background-image: url('../assets/img/section-bg/bw-03.jpg');
}
</style>