<template>
  <select v-model="$i18n.locale" class="bg-transparent active:bg-white uppercase">
    <option class="text-black hover:text-white"
      v-for="(lang, i) in langs"
      :key="`lang-${i}`"
      :value="lang"
    >
      {{ lang }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'SelectLocale',
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { langs: ['fr', 'en'] }
  }
}
</script>