<template>
    <div class="md:grid md:grid-cols-12 md:gap-6 max-w-screen-xl mx-auto">
        <div class="md:col-span-12">
            <div class="embed-container h-72 sm:h-96">
                <iframe :src="'//vimeo.com/event/'+getVideoId(ticket.video_id)+'/embed'" width="100%" height="100%" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>
<script>
import useTickets from "@/composables/use-tickets";
export default {
    props: {
        ticket: {
            type: Object,
            default: null,
        },
    },
    setup() {
        const { getVideoId } = useTickets();
        return { getVideoId };
    }
};
</script>
<style scoped>
.embed-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    background: black;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
iframe form {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
@media screen and (min-width: 768px) {
    .embed-container {
        height: calc(100vh - 69px);
    }
}
</style>